
.card {
  border-radius: 0.75rem;
  border: 2px solid #1c1c1c;
  .card-title {
    background:  #1c1c1c;
    border-bottom: 2px solid  #121212;
  }
  .card-body {
    padding: 2rem;
    height: calc(100% - 3.5rem);
    border: 0.0625rem solid #121212;
    background-color: #161616;

    .form-input {
      label {
        margin-left: 0.5rem;
        font-size: 18px;
        font-weight: 400;
      }
      input {
        height: 36px;
        border: 1px solid #c8c8c8;
        border-radius: 0.75rem;
        display: flex;
        align-items: center;
      }
    }

    .fr__link {
      color: #fe262a !important;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.form-input>.form-control, .form-input>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  min-width: 0;
  color: #c8c8c8;
  background: #121212;
  border: none;
}
.captcha {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  .captcha__img {
    width: 6.25rem;
    height: 2.5rem;
    margin-right: 0.375rem;
  }
  .captcha__input {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}
.forms {
  min-height: 75vh;
}