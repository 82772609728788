.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background: #58b4c4;
  border: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.nav-pills .nav-link {
  background: #7C7C7C;
  color: #000;
  border: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.nav-pills .nav-link {
  height: 36px;
  margin-right: 0.4rem;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  @media(max-width: 560px) {
    font-size: 16px;
    margin-right: 0.1rem;
  }
  &:hover {
    color: $white-color;
  }
}
.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-content {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border: 2px solid #1C1C1C;
  background: #161616
}
.nav-pills .nav-link {
  border-top-right-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
}