.form-control:focus {
  box-shadow: none;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $search-bar-color;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  top: -2px;
  &::placeholder {
    font-size: 12px;
    color:$search-bar-color;
  }
}
.input-group {
  border: 1px solid $search-bar-color;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  height: 36px;
  .btn {
    color: #fff;
    top: -2px;
  }
}
.input-group>.form-control, .input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  color: $search-bar-color;
  background: #121212;
  border: none;
  margin-left: 0.4rem;
}
