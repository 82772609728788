
.footer {
  background: $light-dark;
  .footer-top {
    background: $black-color;
    color:$white-color;
    font-size: 16px;
    padding: 1.5rem 0;
    @media(max-width: 769px) {
      text-align: center;
    }
    p {
      line-height: 1.4;
      font-weight: 300;
      color: $white-color;
      span {
        font-weight: 400;
        text-decoration: underline;
      }
    }
  }
  .footer-bottom {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    .footer-logo {
      display: flex;
      @media(max-width: 992px) {
        justify-content: center;
      }
    }
    .footer-list {
      display: inline-flex;
      list-style: none;
      text-align: center;
      @media(max-width: 560px) {
        flex-wrap: wrap;
      }
      li {
        padding-top: 0.85rem;
        a {
          color: #fff;
          text-decoration: none;
          font-weight: 600;
          &.custom-dot:before {
            content: "\2022";
            color: #FE262A!important;
            width: 1em;
            padding: 0 1rem;
          }
        }
      }
    }
  }
}