.header {
  transition: all 0.5s;
  z-index:12;
  padding: 23px 0;
  background: #121212;

 &.header-scrolled {
    background: #121212;
    z-index: 12;
    .navbar {
      background: transparent;
    }
  }
  .top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 992px) {
      flex-direction: column;
      margin: 0.5rem 0;
    }
  }
  .logo {
    font-size: 2em;
    margin: 0;
    padding: 0;
    padding-right: 1rem;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 1px;
    a {
      color: $white-color;
    }

    img {
      width:103px;
      height:41px;
      object-fit:cover;
      object-position:50% 50%
    }
  }
}

@media (max-width: 992px) {
  .header {
    border: 0;
  }
}
.navbar {
  padding: 0;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  li {
    position: relative;
    align-items: center;
    display: flex;
    /* float: right; */
    justify-content: flex-end;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 30px;
    text-transform: uppercase;
    font-size: 18px;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
    padding: 0 2rem;
    height: 90px;
    font-weight: 400;
    &:hover {
      background-color: $active-color-blue;
    }
    &:focus {
      color: $white-color;
    }
  }

  .active {
    background: $active-color-blue;
  }

  li:hover > a {
    color: $white-color;
  }
}
/**
* Mobile Navigation
*/

.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
  position: absolute;
  right: 1rem;
  &.bi-x {
    color: $white-color;
  }
}
@media (max-width: 1500px) {
  .header {
    svg {
      width: 90%;
    }
    .navbar {
      ul {
        li {
          a {
            padding: 0 0.5rem;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar {
    background: transparent;
    ul {
      display: none;
    }
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 4rem;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(23, 26, 29, 0.6);
  transition: 0.3s;
  z-index: 999;

  .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background: rgba(23, 26, 29, 0.7);
    overflow-y: auto;
    transition: 0.3s;
  }
  li {
    justify-content: center;
    a {
      padding: 1.5rem 0;
      font-size: 18px;
      color: $white-color!important;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      &:hover {
        color: $white-color;
      }
    }
  }
}



/**aside navigation**/

.aside {
  width: 248px;
  overflow-y: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  @media(max-width: 560px) {
    width: 100%;
  }
}
.list-container {
  background: $light-dark;
  padding: 0 2rem;
  height: calc(100% - 68px - 3rem);
  @media(max-width: 560px) {
    display: none;
  }
  .list-header {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
  }
  .category-list {
    list-style: none;
    padding-left: 0.3rem;
    li {
      margin: 1rem auto;
      a {
        font-size: 16px;
        color: #7C7C7C;
        text-transform: capitalize;
        font-weight: 300;
        &.active {
          color: #fff;
          &:before {
            color: #fff;
          }
          .bi-x {
            border: 2px solid #fff;
          }
        }
        &:focus {
          color: #fff;
          .bi-x {
            border: 2px solid #fff;
          }
          &:before {
            color: #fff;
          }
        }
        &:before {
          content: "\2022";
          color: #7C7C7C;
          width: 1em;
        }
        .bi-x {
          border: 2px solid #7C7C7C;
          border-radius: 50%;
          float: right;
          width: 22px;
          height: 22px;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
.list-container .list-container-mobile {
  display: block;
}
.list-container-mobile {
  background: $light-dark;
  padding: 0 2rem;
  display: block;
}
.show-categories.bi-x {
  margin: 1rem auto;
  text-align: center;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  border: 2px solid $white-color;
  display: flex;
  font-size: 1.3rem;
  justify-content: center;
  align-items: center;
  span, i {
    display: none;
  }
}