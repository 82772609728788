/** COLORS **/
$background-black: #000;
$background-blue: #58B4C4;
$orange-color: #FF8B45;
$light-dark: #161616;

$button-border-lightblue: #cee1e7;
$active-color-blue: #58B4C4;
$black-color: #000;
$white-color: #fff;
$search-bar-color: #C8C8C8;