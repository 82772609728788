@import 'variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@100;300;400;600;700&display=swap');
@import 'components/nav.scss';
@import 'components/footer.scss';
@import 'components/search-bar.scss';
@import 'components/gallery.scss';
@import 'components/tabs.scss';
@import 'components/forms.scss';


body {
  color: #fff;
  background: #000000;
  font-family: 'Exo 2',sans-serif!important;
}
a {
  color: $white-color;
  text-decoration: none;

  &:hover {
    color: $white-color;
    text-decoration: none;
  }
}
/**gallery columns**/
.img-col {
  width: 20%;
  display:none;
  @media(max-width: 1280px) {
    width: 25%;
  }
  @media(max-width: 1100px) {
    width: 33%;
  }
  @media(max-width: 992px) {
    width: 50%;
  }
  @media(max-width: 602px) {
    width: 100%;
  }
}
/**column inside tabs**/
.col-tab-left {
  width: 16%;
  @media(max-width: 1920px) {
    width: 22%;
  }
  @media(max-width: 1620px) {
    width:27%;
  }
  @media(max-width: 1366px) {
    width:30%;
  }
  @media(max-width: 1100px) {
    width: 52%;
  }
  @media(max-width: 640px) {
    width: 100%;
  }
}
.col-tab-right {
  width: 78%;
  @media(max-width: 1620px) {
    width: 72%;
  }
  @media(max-width: 1366px) {
    width: 68%;
  }
  @media(max-width: 1100px) {
    width: 100%;
  }
  @media(max-width: 640px) {
    width: 100%;
  }
}
.light-dark-bk {
  background: $light-dark;
}
.rows-select {
  max-width: 100%;
}
/**select with icon**/
.form-select{
  padding: .3125rem 3rem .3125rem 1rem;
  background-image: url(../svg/angle-down.svg) !important;
  background-repeat: no-repeat, repeat!important;
  background-position: right 1rem center!important;
  border-radius: .25rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #d8e2ef;
  color: #fff!important;
  background-size: 1rem!important;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0,0,0,0.075);
  outline:none;
}

/**custom containers width**/
.custom-25 {
  width: 25%;
  @media(max-width: 1100px) {
    width: 100%;
    text-align: center;
  }
}
.custom-50 {
  width: 50%;
  @media(max-width: 1100px) {
    width: 100%;
    text-align: center;
  }
}
.custom-75 {
  width: 75%;
  @media(max-width: 1366px) {
    width: 100%;
    text-align: center;
  }
}
.custom-80 {
  width: 80%;
  @media(max-width: 769px) {
    width: 100%;
  }
}
.custom-90 {
  width: 90%;
  @media(max-width: 769px) {
    width: 100%;
  }
}
.custom-95 {
  width: 95%;
  @media(max-width: 1100px) {
    text-align: center;
  }
}
.custom-100 {
  width: 100%;
  @media(max-width: 1100px) {
    text-align: center;
  }
}
.background-none {
  background: none;
}


/**text colors, font-weight, sizes**/
.header-row {
  .text-header {
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    white-space: nowrap;
    @media(max-width: 1190px) {
      margin: 0;
      white-space: normal;
    }
  }
  .text-subhader {
    color: $background-blue;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    @media(max-width: 1190px) {
      margin: 0;
      white-space: normal;
    }
  }
}
.tab-text-header, .tab-text-subheader {
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
}
.tab-text-subheader {
  color: $background-blue;
  margin-left: 0.5rem;
}
.description {
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  line-height: 1.2;
}


/**order*/
@media only screen and (max-width: 992px) {
  .first {
    order: 2;
  }
  .second {
    order: 1;
  }
}

/**buttons**/
.login, .sign-up, .main-btn {
  border-radius: 0.75rem;
  border: 2px solid $background-blue;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.5rem 1.5rem;
  font-size: 18px;
  text-transform: uppercase;
  height: 36px;
  cursor: pointer;
  font-weight: 400;
  white-space: nowrap;
  @media(max-width: 1369px) {
    padding: 0.5rem;
    margin: 0 0.2rem;
  }
  &:hover {
    color: #fff;
  }
}
.join,  {
  background: $background-blue;
  border: 2px solid transparent;
}
.join-btn {
  border-radius: 0.75rem;
  border: 2px solid $orange-color;
  background: $orange-color;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.5rem 1.5rem;
  font-size: 18px;
  text-transform: uppercase;
  height: 44px;
  width: 100%;
  cursor: pointer;
  font-weight: 700;
  white-space: nowrap;
}
.join-chatroom {
  border-radius: 0.75rem;
  border: 2px solid $background-blue;
  background: $background-blue;
  margin: 5rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.5rem 1.5rem;
  font-size: 22px;
  text-transform: uppercase;
  height: 119px;
  width: 482px;
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
}

/**iframe container**/
.stage {
  display: block;
  .inner-stage {
    display: block;
    position: relative;
    overflow: hidden;
    height: 0;
    padding-top: 56.25%;
    > img,
    > iframe,
    > object,
    > video,
    > #thisPlayer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
.video-container {
  border-radius: 0.75rem;
  border: 2px solid #1C1C1C;
}

section {
  padding: 20px 0;
}
.top-bar-img {
  width: 100%;
  height: 15px;
  background-image: url(../svg/top-bar.svg);
}
.bottom-bar-img {
  width: 100%;
  height: 30px;
  background-image: url(../svg/bottom-bar.svg);
}

.alert-info, .notification.info {
  background-color: $background-blue;
  color: $white-color;
  a {
    color: #fe262a!important;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.alert, .messages, .notification {
  padding: 0.9375rem;
  margin-bottom: 1.375rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
}