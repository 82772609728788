.gallery {
  .thumbnail-card {
    .image {
      z-index: 1;
      position: relative;
      .thumb-overflow {
        width: 100%;
        position: relative;
        display: block;
        overflow: hidden;
        height: 0;
        //padding-top: 88.25%;
        padding-top: 72.25%;

        img {
          width: 100%;
          top: 0;
          left: 0;
          height: 100%;
          object-fit: cover;
          object-position: center;
          position: absolute;
        }
        .img-thumbnail {
          border: 0;
          padding: 0;
          border-radius: 0;
          background: transparent;
        }
      }
    }
  }
}

.top-icon {
  position: absolute;
  top: 0.6rem;
  right: 12px;
  z-index: 9;
  border-radius: 15px;
  background: rgba(0, 255, 203, 0.6);
  width: 56px;
  height: 18px;
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  .online-dot {
    background: #fe262a;
    border-radius: 100%;
    height: 8px;
    width: 8px;
    display: inline-flex;
    border: 2px solid #fe262a;
    padding-top: 0.25rem;
    margin: 0 0.3rem;
  }
}
.models {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  z-index: 9;
  .models-name {
    font-size: 18px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 400;
  }
  .models-nick {
    font-size: 14px;
    color: #B0DEFF;
    font-weight: 400;
  }
}
.video-link {
  border: 2px solid transparent;
  &:hover {
    border: 2px solid $background-blue;
  }
  &:focus {
    border: 2px solid $background-blue;
  }
}
.content {
  margin-left: 1.5rem;
  height: auto;
  flex: 1;
  @media(max-width: 560px) {
    margin-left: 0;
  }
}